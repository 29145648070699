<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <analytics-statistics-card></analytics-statistics-card>
      </v-col>
    </v-row>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="4"
        order="1"
      >
        <mfr-market-share-pie-chart-watch-list>
        </mfr-market-share-pie-chart-watch-list>
      </v-col>

      <v-col
        cols="12"
        md="8"
        order="2"
      >
        <stacked-time-seriesmonthly series="monthlyStackedSalesByMfr"></stacked-time-seriesmonthly>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
    name: 'SingleMoleculeDashboard',
    components: {
        stackedTimeSeriesmonthly: () => import('@/views/molecule/stackedTimeSeriesmonthly.vue'),
        MfrMarketSharePieChartWatchList: () => import('@/components/charts/MfrMarketSharePieChartWatchList.vue'),
        AnalyticsStatisticsCard: () => import('@/views/molecule/AnalyticsStatisticsCard.vue'),
    },
    setup() {
        return {

        }
    }
}
</script>
